import { Box, Grid, Typography } from "@mui/material";

import logo10 from "../assets/logo10.jpeg";
import logo11 from "../assets/logo11.jpeg";
import logo12 from "../assets/logo12.png";
import logo13 from "../assets/logo13.jpeg";
import logo14 from "../assets/logo14.png";
import logo15 from "../assets/logo15.jpg";
import logo16 from "../assets/logo16.jpeg";
import logo2 from "../assets/logo2.jpeg";
import logo3 from "../assets/logo3.jpeg";
import logo4 from "../assets/logo4.jpeg";
import logo5 from "../assets/logo5.jpeg";
import logo6 from "../assets/logo6.jpeg";
import logo7 from "../assets/logo7.jpeg";
import logo8 from "../assets/logo8.png";
import logo9 from "../assets/logo9.jpeg";

export default function References() {
  return (
    <Box
      sx={{
        paddingY: "2rem",
        width: {
          xs: "95%",
          sm: "95%",
          md: "95%",
          lg: "80%",
          xl: "80%",
        },
      }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: "1.4rem",
            sm: "1.4rem",
            md: "2rem",
            lg: "2rem",
            xl: "2rem",
          },
          marginBottom: "2rem",
        }}
      >
        Referanslarımız
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4} md={2}>
          <img
            src={logo13}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo3}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo4}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo5}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo6}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo7}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo8}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo9}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo10}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo11}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo2}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo12}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo14}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo15}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <img
            src={logo16}
            alt="logo1"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
