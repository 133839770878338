import { Box, Grid, Typography } from "@mui/material";
import {
  BusinessCenter,
  Campaign,
  School,
  SettingsSuggest,
} from "@mui/icons-material/";

import bg from "../assets/bg.jpeg";
import logo from "../assets/logo-white.png";

const list = [
  {
    title: "Konsept Belirleme",
    description: "Hayalinizin hayattaki karşılığını belirliyoruz.",
    icon: <img src={logo} style={{ width: "2.4rem", height: "2.4rem" }} />,
  },
  {
    title: "Projelendirme",
    description:
      "Hayalinizi (bulunduğunuz il/ilçe ruhsat yönetmelikleri dahil) kağıt üstünde çalışan bir hale getiriyoruz.",
    icon: <img src={logo} style={{ width: "2.4rem", height: "2.4rem" }} />,
  },
  {
    title: "Fizibilite",
    description:
      "Hayalinizin bulunduğunuz ve bulduğunuz konum için matematiğini çıkarıyoruz.",
    icon: <img src={logo} style={{ width: "2.4rem", height: "2.4rem" }} />,
  },
  {
    title: "Operasyon",
    description:
      "Menünüzü, menününüzün gerektirdiği servis ve hazırlık ekipmanlarını, çalışan ihtiyacınızı belirliyor, operasyonel verimliliğinizi kurguluyoruz.",
    icon: <img src={logo} style={{ width: "2.4rem", height: "2.4rem" }} />,
  },
  {
    title: "Teknoloji",
    description:
      "Kendi geliştirdiğimiz yazılımlarımızla, tüm operasyonunuzu kayıt altına almanızı sağlamakla kalmıyor,maliyet/karlılık, verimlilik, stok takibi, ön muhasebe ve satın alma süreçlerini kolaylıkla takip edebilmenizi sağlıyoruz.",
    icon: <img src={logo} style={{ width: "2.4rem", height: "2.4rem" }} />,
  },
  {
    title: "Ek Hizmet Mimari",
    description:
      "Talebiniz ve bütçeniz doğrultusunda gerekli mimari ve hatta işçilik desteğini sağlıyoruz.",
    icon: <img src={logo} style={{ width: "2.4rem", height: "2.4rem" }} />,
  },
];

export default function Services() {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <img
        src={bg}
        style={{
          objectFit: "cover",
          width: "100vw",
          height: "100vh",
          filter: "brightness(60%)",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: {
            xs: "4%",
            sm: "4%",
            md: "10%",
            lg: "10%",
            xl: "10%",
          },
          backdropFilter: "blur(2.1px)",
          WebkitBackdropFilter: "blur(2.1px)",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "2rem",
              sm: "2rem",
              md: "3rem",
              lg: "4rem",
              xl: "4rem",
            },
            color: "white",
            textAlign: "center",
          }}
        >
          Hizmetlerimiz
        </Typography>
        <Grid container spacing={0}>
          {list.map((item, index) => (
            <Grid
              item
              xs={6}
              md={4}
              key={index}
              sx={{
                padding: "1rem",
              }}
            >
              {item.icon}
              <Typography
                sx={{
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "2rem",
                    lg: "2rem",
                    xl: "2rem",
                  },
                  color: "white",
                }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "0.6rem",
                    sm: "0.6rem",
                    md: "1.2rem",
                    lg: "1.2rem",
                    xl: "1.2rem",
                  },
                  color: "white",
                }}
              >
                {item.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
