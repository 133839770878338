import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { db, storage } from "../../firebase";
import { deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import NoteIcon from "@mui/icons-material/Note";
import SaveIcon from "@mui/icons-material/Save";

const categories = [
  {
    name: "Dış Mimari",
    weight: 0.09,
    subcategories: ["Otopark", "Tabela", "Dış cephe", "Aydınlatma", "Peyzaj"],
  },
  {
    name: "Havalandırma / İklimlendirme",
    weight: 0.22,
    subcategories: [
      "Yeterli gölgelik",
      "Yeterli açık alan",
      "Yeterli havalandırma",
      "Yeterli iklimlendirme",
      "Yeterli kapalı alan",
    ],
  },
  {
    name: "Menü",
    weight: 0.19,
    subcategories: [
      "Görsel tasarım yeterli mi?",
      "Online ulaşım mevcut mu?",
      "Seçenekler konseptle uygun mu?",
      "Seçenekler beslenme türlerine uygun mu?",
      "Fiyat performans uygun mu?",
    ],
  },
  {
    name: "Tuvaletler",
    weight: 0.12,
    subcategories: [
      "Bakımlı mı?",
      "Temiz mi?",
      "Tasarruflu mu?",
      "Engelliler düşünülmüş mü?",
      "Giriş sembolleri evrensel mi?",
    ],
  },
  {
    name: "Personel",
    weight: 0.17,
    subcategories: [
      "Kılık kıyafet uygun mu?",
      "Kişisel bakım uygun mu?",
      "Servis alanına hakim mi?",
      "Personel sayısı doğru sayıda mı?",
      "Menü bilgisi yeterli mi?",
      "Güleryüzlü mü?",
      "Karşılama var mı?",
      "Uğurlama var mı?",
    ],
  },
  {
    name: "İç Mimari",
    weight: 0.07,
    subcategories: [
      "Fonksiyonel mi?",
      "Tadilat ihtiyacı var mı?",
      "Yeterli mi?",
      "Masa sandalye uygun mu?",
      "Servis ekipmanları uygun mu?",
      "İç aydınlatmalar doğru mu?",
    ],
  },
  {
    name: "Konum ve Ulaşım",
    weight: 0.14,
    subcategories: [
      "Yaya yolu var mı?",
      "Araç yolu var mı?",
      "Toplu taşıma var mı?",
      "Merkezi mi?",
      "Konsepte uygun mu?",
    ],
  },
];

const CafeAssessmentForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [capturedImage, setCapturedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [formHeader, setFormHeader] = useState({
    assessorName: "",
    cafeName: "",
    cafeLocation: "",
    assessmentDate: new Date().toISOString().split("T")[0],
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [currentNote, setCurrentNote] = useState({
    category: "",
    subcategory: "",
    note: "",
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [totalPoints, setTotalPoints] = useState(0);
  const [categoryPoints, setCategoryPoints] = useState({});

  useEffect(() => {
    const loadData = async () => {
      if (id) {
        setIsLoading(true);
        const docRef = doc(db, "cafeAssessments", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setFormData(data.formData || {});
          setFormHeader(data.formHeader || {});
        }
        setIsLoading(false);
      }
    };
    loadData();
  }, [id]);

  useEffect(() => {
    calculatePoints();
  }, [formData]);

  const calculatePoints = () => {
    let total = 0;
    const newCategoryPoints = {};

    categories.forEach((category) => {
      const categoryData = formData[category.name];
      if (categoryData && categoryData.subcategories) {
        let categoryTotal = 0;
        let subCategoriesCount = 0;
        Object.values(categoryData.subcategories).forEach((subcategory) => {
          if (subcategory.score !== undefined) {
            categoryTotal += subcategory.score;
            subCategoriesCount++;
          }
        });
        if (subCategoriesCount > 0) {
          const categoryAverage = categoryTotal / subCategoriesCount;
          const categoryPoint = (categoryAverage * category.weight * 100) / 3;
          newCategoryPoints[category.name] = categoryPoint;
          total += categoryPoint;
        }
      }
    });

    setTotalPoints(total);
    setCategoryPoints(newCategoryPoints);
  };

  const saveToFirestore = async (newFormData, newFormHeader) => {
    if (!id) return;
    setIsSaving(true);
    try {
      await setDoc(doc(db, "cafeAssessments", id), {
        formData: newFormData,
        formHeader: newFormHeader,
      });
    } catch (error) {
      console.error("Error saving to Firestore: ", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleHeaderChange = (field, value) => {
    const newFormHeader = { ...formHeader, [field]: value };
    setFormHeader(newFormHeader);
    saveToFirestore(formData, newFormHeader);
  };

  const handleRatingChange = (category, subcategory, value) => {
    const newFormData = {
      ...formData,
      [category]: {
        ...formData[category],
        subcategories: {
          ...formData[category]?.subcategories,
          [subcategory]: {
            ...formData[category]?.subcategories?.[subcategory],
            score: value,
          },
        },
      },
    };
    setFormData(newFormData);
    saveToFirestore(newFormData, formHeader);
  };

  const handleNoteChange = (note) => {
    setCurrentNote((prev) => ({ ...prev, note }));
  };

  const openNoteDialog = (category, subcategory = "") => {
    const noteData = subcategory
      ? formData[category]?.subcategories?.[subcategory] || {}
      : formData[category] || {};

    setCurrentNote({
      category,
      subcategory,
      note: noteData.note || "",
      imageUrl: noteData.imageUrl || "",
    });
    setCapturedImage(null);
    setOpenDialog(true);
  };
  const handleCapture = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCapturedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (imageFile) => {
    if (!imageFile) return null;
    const storageRef = ref(
      storage,
      `images/${id}/${Date.now()}_${imageFile.name}`
    );
    await uploadBytes(storageRef, imageFile);
    return await getDownloadURL(storageRef);
  };
  const saveNote = async () => {
    setIsLoading(true);
    let imageUrl = currentNote.imageUrl;
    if (capturedImage) {
      const imageFile = await fetch(capturedImage).then((r) => r.blob());
      imageUrl = await uploadImage(imageFile);
    }

    const newFormData = { ...formData };
    const noteData = {
      note: currentNote.note,
      imageUrl: imageUrl,
    };

    if (currentNote.subcategory) {
      newFormData[currentNote.category] = {
        ...newFormData[currentNote.category],
        subcategories: {
          ...newFormData[currentNote.category]?.subcategories,
          [currentNote.subcategory]: {
            ...newFormData[currentNote.category]?.subcategories?.[
              currentNote.subcategory
            ],
            ...noteData,
          },
        },
      };
    } else {
      newFormData[currentNote.category] = {
        ...newFormData[currentNote.category],
        ...noteData,
      };
    }
    setFormData(newFormData);
    await saveToFirestore(newFormData, formHeader);
    setOpenDialog(false);
    setCapturedImage(null);
    setIsLoading(false);
  };

  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    setCurrentNote((prev) => ({
      category: newCategory,
      subcategory: "",
      note: formData[newCategory]?.note || "",
    }));
  };

  const handleSubcategoryChange = (event) => {
    const newSubcategory = event.target.value;
    setCurrentNote((prev) => ({
      ...prev,
      subcategory: newSubcategory,
      note:
        formData[prev.category]?.subcategories?.[newSubcategory]?.note || "",
    }));
  };

  const handleDeleteAssessment = async () => {
    if (window.confirm("Are you sure you want to delete this assessment?")) {
      try {
        console.log({ id });
        await deleteDoc(doc(db, "cafeAssessments", id));
        // go to /assesments
        navigate("/assessment");
      } catch (error) {
        console.error("Error deleting assessment:", error);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        position: "relative",
      }}
    >
      {isSaving && (
        <CircularProgress
          size={24}
          sx={{ position: "fixed", top: "16px", right: "16px", zIndex: 10 }}
        />
      )}
      <Container maxWidth="md">
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            color: "#1b1b1b",
            fontWeight: 600,
            fontSize: { xs: "24px", sm: "28px", md: "32px", lg: "36px" },
            marginTop: { xs: "20px", sm: "30px", md: "40px", lg: "50px" },
          }}
        >
          Kafe Zinciri Yerinde Değerlendirme Formu
        </Typography>

        <Card sx={{ mb: 4, position: "relative" }}>
          <CardHeader title="Değerlendirme Bilgileri" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Toplam Puan: {totalPoints.toFixed(2)} / 100
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Kafe Adı"
                  value={formHeader.cafeName}
                  onChange={(e) =>
                    handleHeaderChange("cafeName", e.target.value)
                  }
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Kafe Lokasyonu"
                  value={formHeader.cafeLocation}
                  onChange={(e) =>
                    handleHeaderChange("cafeLocation", e.target.value)
                  }
                />
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>

        {categories.map((category) => (
          <Card key={category.name} sx={{ mb: 2, position: "relative" }}>
            <CardHeader
              title={`${category.name} - Puan: ${(
                categoryPoints[category.name] || 0
              ).toFixed(2)}`}
              action={
                <IconButton
                  onClick={() => openNoteDialog(category.name)}
                  aria-label="Add note"
                >
                  {formData[category.name]?.note ? (
                    <NoteIcon color="primary" />
                  ) : (
                    <NoteAddIcon />
                  )}
                </IconButton>
              }
            />
            <CardContent>
              {formData[category.name]?.note && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  Not: {formData[category.name].note}
                </Typography>
              )}
              {category.subcategories.map((subcategory) => (
                <Box key={subcategory} sx={{ mb: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                      <Typography component="legend">{subcategory}</Typography>
                    </Grid>
                    <Grid item>
                      <Rating
                        name={`${category.name}-${subcategory}`}
                        value={
                          formData[category.name]?.subcategories?.[subcategory]
                            ?.score || 0
                        }
                        onChange={(event, newValue) => {
                          handleRatingChange(
                            category.name,
                            subcategory,
                            newValue
                          );
                        }}
                        max={3}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() =>
                          openNoteDialog(category.name, subcategory)
                        }
                        aria-label="Add subcategory note"
                      >
                        {formData[category.name]?.subcategories?.[subcategory]
                          ?.note ? (
                          <NoteIcon color="primary" />
                        ) : (
                          <NoteAddIcon />
                        )}
                      </IconButton>
                    </Grid>
                  </Grid>
                  {formData[category.name]?.subcategories?.[subcategory]
                    ?.imageUrl && (
                    <Box mt={1}>
                      <img
                        src={
                          formData[category.name]?.subcategories?.[subcategory]
                            ?.imageUrl
                        }
                        alt="Note"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    </Box>
                  )}
                  {formData[category.name]?.subcategories?.[subcategory]
                    ?.note && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 2 }}
                    >
                      Not:{" "}
                      {formData[category.name].subcategories[subcategory].note}
                    </Typography>
                  )}
                </Box>
              ))}
            </CardContent>
          </Card>
        ))}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Not Ekle/Düzenle
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  // position: "fixed",
                  top: "16px",
                  right: "16px",
                  zIndex: 10,
                }}
              />
            )}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="normal">
              <InputLabel>Bölüm</InputLabel>
              <Select
                value={currentNote.category}
                onChange={handleCategoryChange}
                label="Bölüm"
              >
                {categories.map((category) => (
                  <MenuItem key={category.name} value={category.name}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {currentNote.category && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Öğe</InputLabel>
                <Select
                  value={currentNote.subcategory}
                  onChange={handleSubcategoryChange}
                  label="Öğe"
                >
                  <MenuItem value="">
                    <em>Bölüm Notu</em>
                  </MenuItem>
                  {categories
                    .find((cat) => cat.name === currentNote.category)
                    ?.subcategories.map((subcategory) => (
                      <MenuItem key={subcategory} value={subcategory}>
                        {subcategory}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

            <TextField
              autoFocus
              margin="dense"
              id="note"
              label="Notunuzu girin"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={currentNote.note}
              onChange={(e) => handleNoteChange(e.target.value)}
            />

            <Box mt={2}>
              <input
                accept="image/*"
                id="icon-button-file"
                type="file"
                capture="environment"
                onChange={handleCapture}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <label htmlFor="icon-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<CameraAltIcon />}
                >
                  Fotoğraf Çek
                </Button>
              </label>
            </Box>

            {capturedImage && (
              <Box mt={2}>
                <img
                  src={capturedImage}
                  alt="Captured"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <IconButton onClick={() => setOpenDialog(false)} aria-label="Kapat">
              <CloseIcon />
            </IconButton>
            <IconButton onClick={saveNote} color="primary" aria-label="Kaydet">
              <SaveIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
        <Button
          fullWidth
          sx={{
            textTransform: "none",
            fontSize: 20,
          }}
          color="error"
          onClick={handleDeleteAssessment}
        >
          Sil
        </Button>
      </Container>
    </Box>
  );
};

export default CafeAssessmentForm;
