import {
  Add as AddIcon,
  ArrowForward,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";

import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

const AssessmentFormsPage = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [newAssessmentName, setNewAssessmentName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchAssessments();
  }, []);

  const fetchAssessments = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "cafeAssessments"));
      const assessmentList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAssessments(assessmentList);
    } catch (error) {
      console.error("Error fetching assessments:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateNewAssessment = async () => {
    if (newAssessmentName.trim()) {
      try {
        const docRef = await addDoc(collection(db, "cafeAssessments"), {
          formHeader: {
            cafeName: newAssessmentName,
            assessmentDate: new Date().toISOString().split("T")[0],
          },
          formData: {},
        });
        setOpenDialog(false);
        setNewAssessmentName("");
        navigate(`/assessment/${docRef.id}`);
      } catch (error) {
        console.error("Error creating new assessment:", error);
      }
    }
  };

  const handleDeleteAssessment = async (id) => {
    if (window.confirm("Are you sure you want to delete this assessment?")) {
      try {
        await deleteDoc(doc(db, "cafeAssessments", id));
        fetchAssessments();
      } catch (error) {
        console.error("Error deleting assessment:", error);
      }
    }
  };

  const handleEditAssessment = (id) => {
    navigate(`/assessment/${id}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        position: "relative",
      }}
    >
      {/* {loading ? ( */}
      <Box
        sx={{
          background:
            "linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0))",
          backdropFilter: "blur(4px)",
          webkitBackdropFilter: "blur(4px)",
          width: "100%",
          height: "100vh",
          display: loading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          zIndex: 1000,
        }}
      >
        <CircularProgress />
      </Box>
      {/* ) :  */}
      <Container
        maxWidth="md"
        sx={{
          margin: 1,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            color: "#1b1b1b",
            fontWeight: 600,
            fontSize: {
              xs: "24px",
              sm: "28px",
              md: "32px",
              lg: "36px",
            },
            marginTop: {
              xs: "20px",
              sm: "30px",
              md: "40px",
              lg: "50px",
            },
          }}
        >
          Idegastro Değerlendirme Formları
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
          sx={{
            textTransform: "none",
          }}
        >
          Yeni Değerlendirme Oluştur
        </Button>
        <Button
          // variant="contained"
          color="primary"
          startIcon={<ArrowForward />}
          onClick={() => navigate("/analytics")}
          sx={{
            textTransform: "none",
            marginLeft: 2,
          }}
        >
          Rapora Git
        </Button>

        <List>
          {assessments?.map((assessment) => (
            <ListItem
              key={assessment.id}
              sx={{
                // borderBottom: "1px solid #ccc",
                marginBottom: 1,
                borderRadius: 5,
              }}
              onClick={() => handleEditAssessment(assessment.id)}
            >
              <ListItemText
                primary={assessment.formHeader.cafeName}
                secondary={assessment.formHeader.assessmentDate}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => handleEditAssessment(assessment.id)}
                >
                  <EditIcon />
                </IconButton>
                {/* <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteAssessment(assessment.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton> */}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Yeni Değerlendirme Oluştur</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Kafe Adı"
              type="text"
              fullWidth
              value={newAssessmentName}
              onChange={(e) => setNewAssessmentName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>İptal</Button>
            <Button onClick={handleCreateNewAssessment} color="primary">
              Oluştur
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AssessmentFormsPage;
