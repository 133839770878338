import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/logo-white.png";
import { useState } from "react";

export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        zIndex: 100,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginY: "1rem",
        width: {
          xs: "95%",
          sm: "95%",
          md: "95%",
          lg: "80%",
          xl: "80%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          style={{
            height: "4rem",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: "white",
              paddingLeft: "1rem",
              fontWeight: "bold",
              fontSize: {
                xl: "1rem",
                lg: "1rem",
                md: "0.8rem",
                sm: "0.8rem",
                xs: "0.8rem",
              },
            }}
          >
            Şans,asla bir iş modeli değildir !
          </Typography>
          <Typography
            sx={{
              color: "white",
              paddingLeft: "1rem",
              alignSelf: "flex-end",
              fontSize: {
                xl: "0.8rem",
                lg: "0.8rem",
                md: "0.6rem",
                sm: "0.6rem",
                xs: "0.6rem",
              },
            }}
          >
            Anthony Bourdain
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "flex",
            xl: "flex",
          },
        }}
      >
        <Typography
          sx={{ paddingRight: "2rem", fontWeight: "bold", color: "white" }}
        >
          Anasayfa
        </Typography>
        <Typography
          sx={{ paddingRight: "2rem", fontWeight: "bold", color: "white" }}
        >
          Hakkımızda
        </Typography>
        <Typography
          sx={{ paddingRight: "2rem", fontWeight: "bold", color: "white" }}
        >
          Hizmetlerimiz
        </Typography>
        <Typography
          sx={{ paddingRight: "2rem", fontWeight: "bold", color: "white" }}
        >
          İş Sürecimiz
        </Typography>
        <Typography
          sx={{ paddingRight: "2rem", fontWeight: "bold", color: "white" }}
        >
          Referanslarımız
        </Typography>
        <Typography
          sx={{ paddingRight: "2rem", fontWeight: "bold", color: "white" }}
        >
          İletişim
        </Typography>
      </Box>
    </Box>
  );
}
