import { Box, Typography } from "@mui/material";

import { Header } from "../components";
import ss from "../assets/ss-video.jpeg";
import { useState } from "react";
import video from "../assets/bg-video.mp4";

const FullPageVideo = ({}) => {
  const [loaded, setLoaded] = useState(false);
  const onVideoLoad = () => {
    setLoaded(true);
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          zIndex: 0,
          filter: "brightness(60%)",
          backgroundColor: "black",
          visibility: !loaded ? "visible" : "hidden",
        }}
      >
        <img
          src={ss}
          id="alt-image"
          alt="logo"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
      <video
        // ref={video}
        autoPlay
        muted
        loop
        playsInline
        onLoadedData={onVideoLoad}
        style={{
          width: "100vw",
          height: "100vh",
          objectFit: "cover",
          zIndex: 1,
          filter: "brightness(60%)",
          visibility: loaded ? "visible" : "hidden",
        }}
      >
        <source src={video} type="video/mp4" />
      </video>
    </>
  );
};

export default function Landing() {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Header />
      <FullPageVideo />
      <Box
        sx={{
          position: "absolute",
          top: {
            xs: "20%",
            sm: "20%",
            md: "20%",
            lg: "20%",
            xl: "20%",
          },
          zIndex: 100,
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
          height: "80vh",
          width: {
            xs: "95%",
            sm: "95%",
            md: "95%",
            lg: "80%",
            xl: "80%",
          },
          marginTop: {
            xs: "1rem",
            sm: "1rem",
            md: "1rem",
            lg: "2rem",
            xl: "2rem",
          },
        }}
      >
        <Box
          sx={{
            width: {
              xs: "95%",
              sm: "95%",
              md: "95%",
              lg: "80%",
              xl: "80%",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "1.4rem",
                sm: "1.4rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              },
              color: "white",
            }}
          >
            Misyonumuz{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              color: "white",
              paddingBottom: "1rem",
            }}
          >
            Hayalinizdeki sofrayı kurmak
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "1.4rem",
                sm: "1.4rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              },
              color: "white",
            }}
          >
            Vizyonumuz{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              color: "white",
              paddingBottom: "1rem",
            }}
          >
            Sofranızı hep taze tutmak{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "1.4rem",
                sm: "1.4rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              },
              color: "white",
            }}
          >
            Hakkımızda
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              color: "white",
            }}
          >
            Herkesin her şeyi tek başına bilemeyeceği ve yapamayacağı esası ile
            işletmeci/şef İlker Ozan Gündoğdu tarafından kurulmuştur.Her biri
            konusunda uzman profesyonellerden oluşan idegastro hayallerinizi en
            makul şekilde gerçekleştirmeniz için 20 yılı aşkın deneyime sahip ve
            100 den fazla işletmeye hizmet vermiş uzman bir kolektiftir.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
