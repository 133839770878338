import {
  AssessmentForm,
  AssessmentForms,
  CafeAssessmentAnalytics,
  Footer,
  Landing,
  Process,
  References,
  Services,
} from "./components";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { Box } from "@mui/material";

const Home = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Landing />
      <Services />
      <Process />
      <References />
      <Footer />
    </Box>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/assessment/:id" element={<AssessmentForm />} />
        <Route path="/assessment" element={<AssessmentForms />} />
        <Route path="/analytics" element={<CafeAssessmentAnalytics />} />
      </Routes>
    </Router>
  );
}

export default App;
