import { Box, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Box
      sx={{
        width: "100vw",
      }}
    >
      <Typography
        sx={{
          paddingY: "2rem",
          textAlign: "center",
          fontSize: {
            xs: "1rem",
            sm: "1rem",
            md: "1rem",
            lg: "1rem",
            xl: "1rem",
          },
        }}
        align="center"
      >
        © 2024 Tüm Hakları Saklıdır. | İde Gastro
      </Typography>
    </Box>
  );
}
