import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB4ThbiEm0hV8y5tLpw8weLIfXzFD2l6bg",
  authDomain: "idegastro.firebaseapp.com",
  projectId: "idegastro",
  storageBucket: "idegastro.appspot.com",
  messagingSenderId: "996860950272",
  appId: "1:996860950272:web:5f12d598dd727268ec65e5",
  measurementId: "G-6KHV8KDQ5X",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app, "gs://idegastro.appspot.com");

export { db, storage };
