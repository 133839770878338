import { Box, Button, Typography } from "@mui/material";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function Services() {
  const navigateToWhatsapp = () => {
    const whatsappUrl = `https://wa.me/${+905337133610}?text=${encodeURIComponent(
      ""
    )}`;
    window.open(whatsappUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "50vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: "1.4rem",
            sm: "1.4rem",
            md: "2.4rem",
            lg: "2.4rem",
            xl: "2.4rem",
          },
          textAlign: "center",
          alignSelf: "center",
          marginBottom: "1rem",
        }}
      >
        Hep biz anlattık sıra sizi dinlemekte
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "1.6rem",
            sm: "1.6rem",
            md: "2rem",
            lg: "2rem",
            xl: "2rem",
          },
          marginBottom: "1rem",

        }}
      >
        İlker Ozan Gündoğdu
      </Typography>
      <Button
        sx={{
          textTransform: "none",
          backgroundColor: "#05a884",
          // borderColor:"#05a884",
          color: "white",
          borderRadius: "2rem",
          paddingX:"2rem"
        }}
        onClick={navigateToWhatsapp}
        startIcon={<WhatsAppIcon />}
      >
        İletişim
      </Button>
    </Box>
  );
}
