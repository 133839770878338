import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";

import { db } from "../../firebase";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884d8"];

const CafeAssessmentAnalytics = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [overallStats, setOverallStats] = useState({});
  const [categoryAverages, setCategoryAverages] = useState([]);
  const [topPerformers, setTopPerformers] = useState([]);
  const [scatterData, setScatterData] = useState([]);

  useEffect(() => {
    fetchAssessments();
  }, []);

  const fetchAssessments = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "cafeAssessments"));
      const assessmentData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAssessments(assessmentData);
      processData(assessmentData);
    } catch (error) {
      console.error("Error fetching assessments:", error);
    } finally {
      setLoading(false);
    }
  };

  const processData = (data) => {
    // Calculate overall stats
    const totalAssessments = data.length;
    const validScores = data
      .map((assessment) => calculateOverallScore(assessment.formData))
      .filter((score) => !isNaN(score));
    const averageScore =
      validScores.length > 0
        ? validScores.reduce((sum, score) => sum + score, 0) /
          validScores.length
        : 0;

    setOverallStats({
      totalAssessments,
      averageScore: averageScore.toFixed(2),
    });

    // Calculate category averages
    const allCategories = data.flatMap((assessment) =>
      Object.keys(assessment.formData || {})
    );
    const uniqueCategories = [...new Set(allCategories)];
    const catAverages = uniqueCategories.map((category) => ({
      name: category,
      value: calculateCategoryAverage(data, category),
    }));
    setCategoryAverages(catAverages);

    // Determine top performers
    const sortedAssessments = [...data]
      .map((assessment) => ({
        name: assessment.formHeader?.cafeName || "Unknown",
        score: calculateOverallScore(assessment.formData),
      }))
      .filter((assessment) => !isNaN(assessment.score))
      .sort((a, b) => b.score - a.score);
    setTopPerformers(
      sortedAssessments.slice(0, 5).map((assessment) => ({
        name: assessment.name,
        score: parseFloat(assessment.score.toFixed(2)),
      }))
    );

    // Prepare scatter chart data
    const scatterData = data
      .map((assessment) => ({
        name: assessment.formHeader?.cafeName || "Unknown",
        x: calculateCategoryScore(assessment.formData, "Müşteri Hizmeti"),
        y: calculateCategoryScore(assessment.formData, "Ürün Değerlendirmesi"),
      }))
      .filter((item) => !isNaN(item.x) && !isNaN(item.y));
    setScatterData(scatterData);

    console.log("Category Averages:", catAverages);
    console.log("Top Performers:", sortedAssessments.slice(0, 5));
    console.log("Scatter Data:", scatterData);
  };

  const calculateOverallScore = (formData) => {
    if (!formData) return NaN;
    const categories = Object.keys(formData);
    if (categories.length === 0) return NaN;

    const categoryScores = categories
      .map((category) => {
        const categoryData = formData[category];
        if (!categoryData || !categoryData.subcategories) return NaN;

        const subcategoryScores = Object.values(categoryData.subcategories)
          .map((subcat) => subcat.score)
          .filter((score) => typeof score === "number");

        return subcategoryScores.length > 0
          ? subcategoryScores.reduce((sum, score) => sum + score, 0) /
              subcategoryScores.length
          : NaN;
      })
      .filter((score) => !isNaN(score));

    return categoryScores.length > 0
      ? categoryScores.reduce((sum, score) => sum + score, 0) /
          categoryScores.length
      : NaN;
  };

  const calculateCategoryAverage = (data, category) => {
    const categoryScores = data
      .map((assessment) => {
        const categoryData = assessment.formData?.[category];
        if (!categoryData || !categoryData.subcategories) return NaN;

        const subcategoryScores = Object.values(categoryData.subcategories)
          .map((subcat) => subcat.score)
          .filter((score) => typeof score === "number");

        return subcategoryScores.length > 0
          ? subcategoryScores.reduce((sum, score) => sum + score, 0) /
              subcategoryScores.length
          : NaN;
      })
      .filter((score) => !isNaN(score));

    return categoryScores.length > 0
      ? parseFloat(
          (
            categoryScores.reduce((sum, score) => sum + score, 0) /
            categoryScores.length
          ).toFixed(2)
        )
      : 0;
  };

  const calculateCategoryScore = (formData, category) => {
    if (!formData || !formData[category] || !formData[category].subcategories)
      return NaN;
    const subcategories = formData[category].subcategories;
    const scores = Object.values(subcategories)
      .map((sub) => sub.score)
      .filter((score) => typeof score === "number");
    return scores.length > 0
      ? scores.reduce((sum, score) => sum + score, 0) / scores.length
      : NaN;
  };

  if (loading) {
    return (
      <Container
        maxWidth="lg"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          color: "#1b1b1b",
          fontWeight: 600,
          fontSize: {
            xs: "24px",
            sm: "28px",
            md: "32px",
            lg: "36px",
          },
          marginTop: {
            xs: "20px",
            sm: "30px",
            md: "40px",
            lg: "50px",
          },
        }}
      >
        Kafe Değerlendirme Analizleri
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Genel İstatistikler
              </Typography>
              <Typography>
                Toplam Değerlendirme: {overallStats.totalAssessments}
              </Typography>
              <Typography>
                Ortalama Puan: {overallStats.averageScore}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                En İyi Performans Gösteren Kafeler
              </Typography>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart data={topPerformers}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="score" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Kategori Ortalamaları
              </Typography>
              {categoryAverages.some((cat) => cat.value > 0) ? (
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={categoryAverages}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      fill="#8884d8"
                      label
                    >
                      {categoryAverages.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              ) : (
                <Typography>Henüz kategori verisi bulunmamaktadır.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Müşteri Hizmeti vs Ürün Değerlendirmesi
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <ScatterChart
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                >
                  <CartesianGrid />
                  <XAxis
                    type="number"
                    dataKey="x"
                    name="Müşteri Hizmeti"
                    unit=" puan"
                  />
                  <YAxis
                    type="number"
                    dataKey="y"
                    name="Ürün Değerlendirmesi"
                    unit=" puan"
                  />
                  <ZAxis type="category" dataKey="name" name="Kafe" />
                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                  <Legend />
                  <Scatter name="Kafeler" data={scatterData} fill="#8884d8" />
                </ScatterChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CafeAssessmentAnalytics;
